.grid_section {
  padding: 5rem 1.5rem 1.5rem;
  text-align: center;
}

.grid {
  padding: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
}

.grid_section p {
  margin: 1rem 0;
}

@media screen and (min-width: 768px) {
  .grid_section {
    padding: 5rem;
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .grid_section {
    padding: 1.5rem;
  }
}
