.image_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  padding-top: 5rem;
}

.image {
  flex: 1;
  order: 2;
  padding: 0 2rem;
}

.image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text_content {
  flex: 1;
  max-width: 680px;
  order: 1;
  padding: 0 3.2rem;
}

@media screen and (min-width: 768px) {
  .image_text {
    padding: 5rem;
    flex-direction: row;
    text-align: left;
  }

  .image {
    order: 1;
    padding: 0;
  }

  .text_content {
    order: 2;
    padding: 0 2.5rem 1rem;
  }

  .text_content h3 span {
    display: block;
  }

  .text_content p {
    width: 100%;
    max-width: 580px;
  }

  .image_right .image {
    order: 2;
  }

  .image_right .text_content {
    order: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .image_text {
    padding: 0 1.5rem;
  }
  .text_content {
    padding-left: 2.5rem;
    padding-right: 1rem;
  }

  .image {
    padding: 0;
  }
  .image_right .text_content {
    padding-left: 1rem;
    padding-right: 2.5rem;
  }
}
