.footer_links {
  padding: 0 0 0.5rem;
}

.footer_links a {
  text-decoration: none;
  font-size: 0.8rem;
  color: #bbb;
  display: inline-block;
  padding: 8px 12px;
}

.footer_links a:hover {
  color: #eee;
}
