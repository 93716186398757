.logo {
  display: block;
  max-width: 160px;
  margin: 0 auto 1rem;
}

.logo img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .logo {
    margin: 0 0 1rem 0;
  }
}
