.footer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 3rem;
  color: #bbb;
  font-size: 0.75rem;
  text-align: center;
}

.footer img {
  max-width: 80px;
}
