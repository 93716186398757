.nav_landing {
  transition: 0.5s ease-out;
  position: fixed;
  padding: 0 1rem;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 1rem;
  z-index: 999;
}

.nav_landing.hide {
  opacity: 0;
  pointer-events: none;
}

.has_bg {
  background-color: #090b13;
}

.nav_landing--left {
  margin-right: auto;
}

.logo_container {
  display: block;
  width: 80px;
  max-height: 70px;
  overflow: hidden;
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .nav_landing {
    padding: 0 2.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .nav_landing {
    padding: 0 1.5rem;
  }
}
